<template>
	<!-- 导入按钮的弹窗 -->
	<el-dialog title="导入" width="40%" @close="$emit('update:dialogImportVisible', false)" :visible.sync="importVisible">
		<div class="import_dialog" style="height: auto; padding: 0">
			<div style="text-align: center">
				<el-upload
					class="upload-demo"
					action
					:show-file-list="false"
					:http-request="exportExcel"
					:multiple="false"
					:file-list="fileList"
					:on-exceed="handleExceed"
				>
					<el-button type="primary" icon="el-icon-upload" round>导入Excel</el-button>
				</el-upload>
			</div>
			<div class="import_dialog_desc">
				<span v-show="errShow" style="color: red" class="import_dialog_desc_download_text" @click="downLoadError"
					>*上传失败，请点击这里下载查看</span
				>
			</div>
			<div class="import_dialog_desc" v-show="Template">
				<span class="import_dialog_desc_download_text" @click="handleDownloadTemplate">Excel模板下载</span>
				<span>（注：导入操作请务必以下载的模板为准！）</span>
			</div>
		</div>
	</el-dialog>
</template>
<script>
export default {
	props: {
		dialogImportVisible: {
			type: Boolean,
			default: false
		},
		importUrl: {
			type: String,
			default: ''
		},
		navActive: {
			type: String
		},
		type: {
			type: String,
			default: ''
		},
		width: {
			type: String,
			default: ''
		},
		Template: {
			type: Boolean,
			default: true
		},
		params: {
			type: Object
		}
	},
	watch: {
		dialogImportVisible() {
			this.importVisible = this.dialogImportVisible;
		}
	},
	computed: {
		dictData() {
			return this.$store.state.app.dict || [];
		}
	},
	data() {
		return {
			fileList: [],
			importVisible: false,
			errShow: false,
			errUrl: ''
		};
	},
	created() {
		this.errShow = false;
	},
	methods: {
		getTemplate(name) {
			//   console.log(this.$store.state.app.dict)
			if (this.dictData.length === 0) {
				this.dictData = this.$store.state.app.dict;
			}

			const dictObj = this.dictData.filter((item) => item.groupId == 'importTemplate').filter((el) => el.dictId == name);

			return dictObj[0].remark;
		},
		exportExcel(event) {
			this.errShow = false;
			if (!event.file || !event.file.name) {
				this.$message.error('文件有误');
				return;
			}
			if (
				['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].indexOf(event.file.type) == -1 &&
				!event.file.name.includes('.xl')
			) {
				this.$message.error('请上传正确Excel文件');
				return;
			}
			const params = new FormData();

			params.append('imgFile', event.file);
			const loading = this.$loading({
				lock: true,
				text: '正在努力导入......',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'loadClass'
			});

			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			// eslint-disable-next-line radix
			const timeout = parseInt(5 * 60000);

			this.$axios
				.post(this.importUrl, params, {
					headers: { 'Content-Type': type },
					timeout
				})
				.then((res) => {
					if (res) {
						if (res.errorDataUrl) {
							this.errShow = true;
							this.errUrl = res.errorDataUrl;
						} else if (res._responseStatusCode === 0) {
							loading.spinner = 'el-icon-success';
							loading.text = '导入成功';
							// this.$message.success("导入成功");
							this.$emit('update:dialogImportVisible', false);
							this.$emit('handleSearch');
						}
						setTimeout(() => {
							loading.close();
						}, 1000);
					}
				});
			this.fileList = [];
		},
		downLoadError() {
			window.location.href = this.errUrl;
		},
		handleExceed() {
			this.$message.warning('请选择单个文件');
		},
		// 下载excel模板
		handleDownloadTemplate() {
			let url = this.getTemplate(this.type) || '';

			if (this.type == 'promotion') {
				url = this.getTemplate('importPromote');
			}
			if (this.type == 'importStaff') {
				url = this.getTemplate('importStaff');
			}
			// 职级模板
			if (this.type == 'professionSystemTemplate') {
				url = this.getTemplate('professionSystemTemplate');
			}
			// 客户员工
			if (this.type == 'importClientTemplate') {
				url = this.getTemplate('importClientTemplate');
			}
			// 薪酬
			if (this.type == 'payroll') {
				url = `${location.origin}/v1/payroll/month/template/export?params=${encodeURIComponent(
					JSON.stringify({ grantType: '0', month: `${this.params.month}` })
				)}`;
			}
			if (this.type == 'achievementsmanagement') {
				url = `${location.origin}/v1/performance/plan/evaluate/export?params=${encodeURIComponent(
					JSON.stringify({ ...this.params, type: Number(this.navActive) + 1 })
				)}`;
			}
			if (this.type == 'performanceLevel') {
				url = `${location.origin}/v1/performance/level/export?params=${encodeURIComponent(JSON.stringify(this.params))}`;
			}
			if (this.type == 'performanceAssess') {
				url = `${location.origin}/v1/performance/assess/affirm/export?params=${encodeURIComponent(JSON.stringify(this.params))}`;
			}
			// 培训train
			if (this.type == 'train') {
				url = `${location.origin}/v1/education/signUp/export?params=${encodeURIComponent(JSON.stringify(this.params))}`;
			}
			//   url = document.location.origin + "/导入员工模板.xlsx";
			window.location.href = url;
		}
	}
};
</script>
<style lang="scss">
.loadClass {
	.el-loading-spinner i {
		font-size: 50px;
	}
	.el-loading-spinner .el-loading-text {
		font-size: 20px;
	}
}
</style>
<style lang="scss" scoped>
.import_dialog {
	width: 100%;
	// height: 200px;
	//   padding-top: 50px;
	.import_dialog_btn {
		width: 300px;
		// height: 90px;
		text-align: center;
		// line-height: 90px;
		// background-color: rgb(64, 158, 255);
		// margin: 0 auto;
		// .import_dialog_btn_inner {
		//   width: 300px;
		//   color: #fff;
		//   height: 100%;
		//   display: block;
		// }
	}
	.import_dialog_desc {
		text-align: center;
		margin-top: 15px;
		.import_dialog_desc_download_text {
			color: rgb(64, 158, 255);
			cursor: pointer;
		}
	}
}
::v-deep.el-dialog__header {
	text-align: center;
	.dialog-title {
		// font-size: 18px;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
	}
}
// ::v-deep.el-dialog__body{
//     border-top: 1px solid #EAEDF1;
// }
</style>
