<template>
	<div class="container">
		<el-row style="width: 100%; margin-bottom: 5px">
			<el-col :span="5">
				<div class="inputBox">
					<span>公司名称</span>
					<el-input v-model="params.name" size="small" @keyup.enter.native="handleSearch()"></el-input>
				</div>
			</el-col>
			<el-button type="primary" size="small" icon="el-icon-search" style="height: 32px" @click="handleSearch()">查询</el-button>
			<el-button type="primary" icon="el-icon-plus" size="small" style="float: right; height: 32px" @click="handleShow">新增</el-button>
		</el-row>
		<div style="width: 100%">
			<el-table :data="tableData.list" stripe slot="empty" v-loading="loadding" class="tableBackground" style="width: 100%">
				<el-table-column type="index" label="序号" :width="50" :index="indexMethod"></el-table-column>
				<el-table-column
					v-for="(cols, index) in tableCols"
					:key="index"
					:prop="cols.values"
					:label="cols.name"
					align="center"
				></el-table-column>
				<el-table-column label="操作" align="center">
					<template v-slot="{ row }">
						<el-dropdown>
							<el-button type="text"><img src="../../../assets/button/操作图标@3x.png" width="16px" /></el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item, i) in commands" @click.native.stop="rowClick(row, item)" :command="item" :key="i">{{
									item.label
								}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div style="width: 100%; float: right">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
		<el-dialog :title="dialogTitle" center width="30%" top="30vh" :before-close="handleClose" :visible.sync="dialogFormVisible">
			<el-form :model="form" ref="form" label-width="100px">
				<el-form-item label="公司名称" prop="name" :rules="[{ required: true, message: '请输入公司名称', trigger: 'blur' }]">
					<el-input style="width: 78%" v-model="form.name" maxlength="30" show-word-limit autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" style="text-align: center">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import * as api from '@/api/customer';
export default {
	name: 'reward',
	components: {},
	data() {
		return {
			form: { name: '' },
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			// 搜索条件对象
			params: {
				name: '',
				pageNo: 1,
				pageSize: 20
			},
			tableData: {
				list: [
					{
						name: 'aff',
						staffNUm: '200',
						creator: 'fsdfs',
						createTime: '2019-11-22'
					}
				]
			},
			tableCols: [
				// {name: '序号',values:'id'},
				{ name: '公司名称', values: 'name' },
				{ name: '员工人数', values: 'staffNum' },
				{ name: '创建人', values: 'creator' },
				{ name: '创建时间', values: 'createTime' }
			],
			labelPosition: 'right',
			rowId: '',
			loadding: false,
			commands: [
				{
					label: '详情',
					value: '1'
				},
				{
					label: '编辑',
					value: '2'
				},
				{
					label: '删除',
					value: '3'
				}
			],
			dialogTitle: '',
			dialogFormVisible: false,
			status: '1' // 1，新增 2，修改
		};
	},
	mounted() {
		this.$nextTick(function () {
			this.handleSearch();
		});
	},
	methods: {
		// 排序
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		startChange(val) {
			this.params.startTime = `${val} 00:00:00`;
		},
		endChange(val) {
			this.params.endTime = `${val} 23:59:59`;
		},
		handleShow() {
			this.dialogFormVisible = true;
			this.status = '1';
			this.dialogTitle = '新增公司';
			this.$nextTick(() => {
				this.$refs['form'].clearValidate();
			});
		},
		async handleSearch() {
			this.loadding = true;
			const params = {
				request: this.params
			};

			await api
				.getCompanyList(params)
				.then((result) => {
					this.tableData = result;
					this.$emit('active', {
						active: '1',
						selectId: '',
						companyList: this.tableData
					});
					this.pagenation.totalNum = result.totalNum;
					this.loadding = false;
				})
				.catch(() => {});
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		},
		rowClick(row, command) {
			this.rowId = row.id;
			if (command.value == '1') {
				this.$emit('active', { active: '2', selectId: row.id });
			} else if (command.value == '2') {
				this.dialogTitle = '编辑公司';
				this.status = '2';
				this.dialogFormVisible = true;
				if (this.$refs['form'] !== undefined) {
					this.$refs['form'].clearValidate();
				}
				this.form.name = row.name;
				this.form.id = row.id;
			} else if (command.value == '3') {
				this.delCompany(row.id);
			}
		},
		save() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					if (this.status == '1') {
						api.saveCompany({ request: this.form }).then((res) => {
							if (res.code == 0) {
								try {
									this.handleSearch();
									this.$message.success('新增成功');
									this.$refs['form'].clearValidate();
									this.$refs['form'].resetFields();
									this.dialogFormVisible = false;
								} catch {
									this.dialogFormVisible = false;
								}
							}
						});
					} else {
						api.updateCompany({
							request: {
								id: this.rowId,
								name: this.form.name
							}
						}).then((res) => {
							if (res.code == 0) {
								this.$message.success('修改成功');
								this.$refs['form'].clearValidate();
								this.$refs['form'].resetFields();
								this.dialogFormVisible = false;
								this.handleSearch();
							}
						});
					}
				}
			});
		},
		delCompany(id) {
			this.$confirm('是否删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				api.delCompany({
					request: {
						id
					}
				}).then((res) => {
					if (res.code == 0) {
						this.$message.success('删除成功');
						this.handleSearch();
					}
				});
			});
		},
		handleClose() {
			this.form.name = '';
			this.dialogFormVisible = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}
.toolBox {
	width: 100%;
	margin-bottom: 10px;
	div {
		float: right;
	}
}
// .inputBox {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 10px;
//   span {
//     width: 50%;
//     text-align: right;
//     margin-right: 10px;
//   }
// }
</style>
<style lang="scss">
.textarea {
	width: 100%;
	.el-form-item__content {
		width: 80%;
	}
}
</style>
