<template>
	<div class="container">
		<el-row style="width: 100%; margin-bottom: 5px">
			<el-col :span="5">
				<div class="inputBox">
					<span>公司名称</span>
					<el-select v-model="params.customerComId" clearable size="small" placeholder="请选择">
						<el-option v-for="item in companys" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="inputBox">
					<span>姓名</span>
					<el-input size="small" v-model="params.userName"></el-input>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="inputBox">
					<span>工号</span>
					<el-input size="small" v-model="params.jobNumber"></el-input>
				</div>
			</el-col>
			<el-button type="primary" size="small" icon="el-icon-search" style="height: 32px" @click="handleSearch()">查询</el-button>
			<div style="float: right">
				<el-button type="primary" icon="el-icon-plus" size="small" style="height: 32px" @click="handleShow">新增</el-button>
				<el-button type="danger" size="small" icon="el-icon-delete" :disabled="selection.length === 0" @click="batchDeleteCustomers"
					>批量删除</el-button
				>
				<el-button type="primary" size="small" style="margin-left: 10px" icon="el-icon-upload" @click="dialogImportVisible = true"
					>导入</el-button
				>
				<el-button type="primary" size="small" icon="el-icon-download" style="margin-left: 10px" @click="outPort">导出</el-button>
			</div>
		</el-row>
		<div style="width: 100%">
			<el-table
				:data="tableData.list"
				slot="empty"
				@selection-change="handleSelectionChange"
				v-loading="loadding"
				class="tableBackground"
				style="width: 100%"
			>
				<!-- <el-table-column type="index"
                         label="序号"
                         :width="50"
                         :index="indexMethod">
        </el-table-column> -->
				<el-table-column type="selection" align="center" width="45"> </el-table-column>
				<el-table-column v-for="(cols, index) in tableCols" :key="index" :prop="cols.values" :label="cols.name" :width="120" align="center">
				</el-table-column>
				<el-table-column fixed="right" align="center" label="操作">
					<template v-slot="{ row }">
						<el-dropdown>
							<el-button type="text">
								<img src="../../../assets/button/操作图标@3x.png" width="16px" />
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item, i) in commands" @click.native.stop="rowClick(row, item)" :command="item" :key="i">{{
									item.label
								}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div style="width: 100%; float: right">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
		<el-dialog :title="dialogTitle" width="70%" center :close-on-click-modal="false" @close="handleClose" :visible.sync="dialogFormVisible">
			<el-form
				:model="staffParams"
				ref="staffParams"
				:rules="rules"
				:label-position="labelPosition"
				label-width="135px"
				:inline="true"
				class="demo-form-inline formItem staffForm"
			>
				<el-col :span="12">
					<el-form-item label="序号" style="font-weight: normal" prop="seqNo">
						<el-input
							v-model="staffParams.seqNo"
							type="number"
							onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="公司" :span="12" prop="customerComId">
						<el-select v-model="staffParams.customerComId" clearable placeholder="请选择">
							<el-option v-for="item in companys" :key="item.id" :label="item.name" :value="item.id"> </el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="姓名" prop="userName">
						<el-input v-model="staffParams.userName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="工号" prop="jobNumber">
						<el-input v-model="staffParams.jobNumber"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="一级部门" prop="departmentName" :rules="{ required: true, message: '请选择一级部门', trigger: 'change' }">
						<el-input v-model="staffParams.departmentName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12" prop="departmentTwo">
					<el-form-item label="二级部门" prop="departmentTwo">
						<el-input v-model="staffParams.departmentTwo"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="三级部门" prop="departmentThree">
						<el-input v-model="staffParams.departmentThree"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="四级部门" prop="departmentFour">
						<el-input v-model="staffParams.departmentFour"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="工作地点" prop="workCity">
						<el-input v-model="staffParams.workCity"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="入职时间" prop="entryTime">
						<el-date-picker
							v-model="staffParams.entryTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="现任岗位" prop="jobConfigName">
						<el-input v-model="staffParams.jobConfigName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="任现岗起始时间" prop="jobTime">
						<el-date-picker
							:picker-options="pickerOptions"
							v-model="staffParams.jobTime"
							value-format="yyyy-MM-dd"
							format="yyyy-MM-dd"
							type="date"
							style="width: 100%"
						></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="直接主管" prop="directSupervisor">
						<el-input v-model="staffParams.directSupervisor"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="主管工号" prop="directNumber">
						<el-input v-model="staffParams.directNumber"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="下属人数" prop="subordinateNum">
						<el-input
							v-model="staffParams.subordinateNum"
							onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="管理站位" prop="managerStation">
						<el-select style="width: 100%" v-model="staffParams.managerStation">
							<el-option v-for="(item, index) in stationList" :key="index" :label="item.dictName" :value="item.dictId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职族">
						<div class="inputDiv" @click="chooseJob">
							{{ staffParams && staffParams.familyName }}
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职类">
						<div class="inputDiv" @click="chooseJob">
							{{ staffParams && staffParams.className }}
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职类子类">
						<div class="inputDiv" @click="chooseJob">
							{{ staffParams && staffParams.subclassName }}
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职位">
						<div class="inputDiv" @click="chooseJob">
							{{ staffParams && staffParams.officeName }}
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职级代码">
						<div class="inputDiv" @click="chooseJob">
							{{ staffParams && staffParams.familyCode }}
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="岗位">
						<div class="inputDiv" @click="chooseJob">
							{{ staffParams && staffParams.nowjobName }}
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职级">
						<div class="inputDiv" @click="chooseJob">
							{{ staffParams && staffParams.level }}
						</div>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="职等" prop="jobEtc">
						<el-select style="width: 100%" v-model="staffParams.jobEtc">
							<el-option v-for="(item, index) in etcList" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-form>
			<div slot="footer" class="dialog-footer" style="clear: both">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" @click="saveStaff">确 定</el-button>
			</div>
		</el-dialog>
		<family :dialogFamily.sync="dialogFamily" @setFamily="setFamily"></family>
		<importmodal @handleSearch="handleSearch" :importUrl="importUrl" :type="type" :dialogImportVisible.sync="dialogImportVisible"></importmodal>
	</div>
</template>

<script>
import * as api from '@/api/customer';
import family from '@/components/family';
import importmodal from '@/components/import';
const validateJobNum = (rule, value, callback) => {
	if (!/^[A-Za-z0-9]+$/gi.test(value)) {
		callback(new Error('工号只能由字母和数字组成'));
	} else {
		callback();
	}
};

export default {
	components: {
		family,
		importmodal
	},
	props: {
		selectId: String,
		companyList: {}
	},
	watch: {
		selectId(newVal) {
			this.getCompanys();
			this.params.customerComId = newVal;
			this.handleSearch();
		},
		companyList(newVal) {
			if (newVal) {
				this.companys = newVal.list;
			}
		}
	},
	data() {
		return {
			type: 'importClientTemplate',
			dialogImportVisible: false,
			importUrl: '/v1/customer/staff/import',
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			// 搜索条件对象
			params: {
				customerComId: '',
				userName: '',
				jobNumber: '',
				pageNo: 1,
				pageSize: 20
			},
			tableData: {},
			tableCols: [
				{ name: '序号', values: 'seqNo' },
				{ name: '公司', values: 'companyName' },
				{ name: '姓名', values: 'userName' },
				{ name: '工号', values: 'jobNumber' },
				{ name: '所在一级部门', values: 'departmentName' },
				{ name: '所在二级部门', values: 'departmentTwo' },
				{ name: '所在三级部门', values: 'departmentThree' },
				{ name: '所在四级部门', values: 'departmentFour' },
				{ name: '工作地点', values: 'workCity' },
				{ name: '入职时间', values: 'entryTime' },
				{ name: '现任岗位', values: 'jobConfigName' },
				{ name: '任现岗起始时间', values: 'jobTime' },
				{ name: '直接主管', values: 'directSupervisor' },
				{ name: '管理站位', values: 'managerStationName' },
				{ name: '下属人数', values: 'subordinateNum' },
				{ name: '职族', values: 'familyName' },
				{ name: '职类', values: 'className' },
				{ name: '职类子类', values: 'subclassName' },
				{ name: '职位', values: 'officeName' },
				{ name: '岗位', values: 'jobName' },
				{ name: '职级', values: 'level' },
				{ name: '职级代码', values: 'familyCode' },
				{ name: '职等', values: 'jobEtc' },
				{ name: '状态', values: 'statusName' },
				{ name: '更新时间', values: 'updateTime' }
			],
			clearFeild: {},
			staffParams: {
				id: '',
				customerStaffId: '',
				seqNo: '',
				customerComId: '',
				saasKey: '',
				userName: '',
				jobNumber: '',
				departmentName: '',
				departmentTwo: '',
				departmentThree: '',
				departmentFour: '',
				workCity: '',
				entryTime: '',
				jobName: '',
				jobTime: '',
				directSupervisor: '',
				directNumber: '',
				subordinateNum: '',
				managerStation: ' ',
				officeJobId: '',
				levelAndCodeId: '',
				jobEtc: '',
				positionId: ''
			},
			defaultFormData: {},
			rules: {
				seqNo: [{ required: true, message: '请输入序号', trigger: 'blur' }],
				customerComId: [{ required: true, message: '请选择公司', trigger: 'change' }],
				jobNumber: [
					{ required: true, message: '请输入工号', trigger: 'blur' },
					{ trigger: 'blur', validator: validateJobNum }
				],
				userName: [{ required: true, message: '请输入员工姓名', trigger: 'blur' }]
			},
			etcList: [
				{ lable: 'A', value: 'A' },
				{ lable: 'B', value: 'B' },
				{ lable: 'C', value: 'C' },
				{ lable: 'D', value: 'D' }
			],
			labelPosition: 'right',
			rowId: '',
			loadding: false,
			commands: [
				{
					label: '编辑',
					value: '1'
				},
				{
					label: '删除',
					value: '2'
				}
			],
			dialogTitle: '',
			dialogFormVisible: false,
			companys: [],
			status: '1', // 1，新增 2，修改
			dialogFamily: false,
			stationList: [], // 管理站位
			// 不能晚于当前时间
			selection: [],
			pickerOptions: {
				disabledDate: (time) => time.getTime() > Date.now()
			}
		};
	},
	mounted() {
		this.$nextTick(function () {
			this.defaultFormData = { ...this.staffParams };
			this.stationList = this.$store.state.app.dict.filter((item) => item.groupId == 'positionSequence');
			this.clearFeild = { ...this.staffParams };
			this.getCompanys();
			this.handleSearch();
		});
	},
	methods: {
		handleSelectionChange(selection) {
			this.selection = selection;
		},
		batchDeleteCustomers() {
			if (this.selection.length > 0) {
				this.$confirm('确定移除选中的员工?').then(() => {
					api.batchDeleteCustomers({
						ids: this.selection.map((v) => ({ id: v.id, customerComId: v.customerComId }))
					}).then((res) => {
						if (res._responseStatusCode === 0) {
							this.$message.success({
								message: '删除成功'
							});
							this.handleSearch();
						}
					});
				});
			}
		},
		// 排序
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		handleShow() {
			this.staffParams = this.clearFeild;
			this.dialogFormVisible = true;
			this.status = '1';
			this.dialogTitle = '新增员工';
			this.$refs['staffParams'].clearValidate();
		},
		getCompanys() {
			api.getCompanyList({
				request: {
					name: '',
					pageNo: 1,
					pageSize: 20
				}
			})
				.then((result) => {
					this.companys = result.list;
				})
				.catch(() => {});
		},
		handleSearch() {
			this.loadding = true;
			const params = {
				request: this.params
			};

			api.getCusStaffList(params)
				.then((result) => {
					this.tableData = result;
					this.pagenation.totalNum = result.totalNum;
					this.loadding = false;
				})
				.catch(() => {});
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		},
		rowClick(row, command) {
			this.rowId = row.id;
			if (command.value == '1') {
				this.dialogTitle = '编辑员工信息';
				this.status = '2';
				this.dialogFormVisible = true;
				this.$nextTick(() => {
					this.$refs['staffParams'].clearValidate();
				});
				this.staffParams = { ...row };
				this.setFamily(row);
			} else if (command.value == '2') {
				this.delStaff(row);
			}
		},
		saveStaff() {
			this.$refs['staffParams'].validate((valid) => {
				if (valid) {
					const key = this.companys.filter((item) => item.id == this.staffParams.customerComId);

					this.staffParams.saasKey = key[0].saasKey;
					if (this.status == '1') {
						api.saveStaff({ request: this.staffParams }).then((res) => {
							if (res.code == 0) {
								this.$message.success('新增成功');
								// this.$refs["staffParams"].clearValidate();
								// this.$refs["staffParams"].resetFields();
								this.dialogFormVisible = false;
								this.handleSearch();
							}
						});
					} else {
						api.updateStaff({
							request: this.staffParams
						}).then((res) => {
							if (res.code == 0) {
								this.$message.success('修改成功');
								// this.$refs["staffParams"].clearValidate();
								// this.$refs["staffParams"].resetFields();
								this.dialogFormVisible = false;
								this.handleSearch();
							}
						});
					}
				}
			});
		},
		delStaff(row) {
			this.$confirm('是否删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				api.delStaff({
					request: {
						id: row.id,
						customerComId: row.customerComId
					}
				}).then((res) => {
					if (res.code == 0) {
						this.$message.success('删除成功');
						this.handleSearch();
					}
				});
			});
		},
		handleClose() {
			this.staffParams = Object.assign({}, {}, this.defaultFormData); // 清空表单
			// this.$refs["staffParams"].resetFields();
			// this.$refs["staffParams"].clearValidate();
			this.dialogFormVisible = false;
		},
		// 职类弹框
		chooseJob() {
			if (this.$store.state.app.range.customerStaffIds.includes(Number(this.staffParams.customerStaffId))) {
				this.$message.warning('当前人员正参与人岗匹配计划，请完成后修改');
				return;
			}
			this.dialogFamily = true;
		},
		setFamily(row) {
			for (const key in row) {
				for (const key1 in this.staffParams) {
					if (key == key1) {
						this.staffParams[key1] = row[key];
					}
				}
			}
			// this.staffParams.familyName = row.familyName;
			// this.staffParams.id=row.id;
			this.staffParams.customerStaffId = row.id;
			// this.staffParams.className = row.className;
			// this.staffParams.subclassName = row.subclassName;
			// this.staffParams.officeName = row.officeName;
			// this.staffParams.familyCode = row.familyCode;
			// this.staffParams.officeCode = row.officeCode;
			this.staffParams.nowjobName = row.jobName;
			// this.staffParams.level = row.level;
			// this.staffParams.levelAndCodeId = row.levelAndCodeId;
			// this.staffParams.officeJobId = row.officeJobId;
		},
		// 导出
		outPort() {
			const outParams = {
				companyName: '',
				customerComId: this.params.customerComId,
				userName: this.params.userName,
				jobNumber: this.params.jobNumber,
				startTime: '',
				endTime: '',
				status: ''
			};

			const outStr = encodeURIComponent(JSON.stringify(outParams));

			const url = `/v1/customer/staff/export?params=${outStr}`;

			window.open(document.location.origin + url);
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}
.toolBox {
	width: 100%;
	margin-bottom: 10px;
	div {
		float: right;
	}
}
// .inputBox {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 10px;
//   span {
//     width: 100px;
//     text-align: right;
//     margin-right: 10px;
//   }
// }
.inputDiv {
	width: 100%;
	height: 40px;
	padding: 0 15px;
	background-color: #fff;
	cursor: pointer;
	background-image: none;
	border-radius: 4px;
	border: 1px solid #dcdfe6;
}
</style>
<style lang="scss">
.textarea {
	width: 100%;
	.el-form-item__content {
		width: 80%;
	}
}
.staffForm .el-input {
	position: relative;
	font-size: 14px;
	display: inline-block;
	width: 100%;
}
.staffForm .el-form-item,
.staffForm .el-select {
	width: 100%;
}
.staffForm .el-form-item__content {
	width: 60%;
}
</style>
