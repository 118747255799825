<template>
  <div class="app-container">
    <div style="position: relative;">
      <el-tabs v-model="activeName"  @tab-click="handleClick">
        <el-tab-pane label="客户公司"
                     name="1">
          <custcompanys ref="company"
                        @active="active"></custcompanys>
        </el-tab-pane>
        <el-tab-pane label="客户员工"
                     name="2"
                     >
          <custEmp ref="employee"
                   :selectId="selectId"
                   :companyList="companyList"></custEmp>
        </el-tab-pane>

      </el-tabs>
    </div>

  </div>
</template>

<script>
import custcompanys from './custCompanys';
import custEmp from './custEmp';
export default {
  name: 'customer',
  components: {
    custcompanys,
    custEmp
  },
  data () {
    return {
      activeName: '1',
      selectId: '',
      companyList: {}
    };
  },
  created () { },
  methods: {
    handleShow () {
      const refName = this.activeName === '1' ? 'company' : 'employee';

      this.$refs[refName].handleShow();
    },
    active (item) {
      this.activeName = item.active;
      this.selectId = item.selectId;
      this.companyList = item.companyList;

    },
    handleClick () {
      const refName = this.activeName === '1' ? 'company' : 'employee';

        this.$refs[refName].handleSearch();
      }
  },
  mounted () { }
};
</script>
