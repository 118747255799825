<template>
	<el-dialog title="职级体系" :visible.sync="dialogFamily" width="70%" center top="5vh" :before-close="cancelSys">
		<el-form :inline="true" class="demo-form-inline">
			<el-form-item class="formLabel" label="职族" style="margin-right: 40px">
				<el-input v-model="searchFamilyName" placeholder="请输入职族名称"></el-input>
			</el-form-item>
			<el-form-item class="formLabel" label="职类" style="margin-right: 40px">
				<el-input v-model="searchClassName" placeholder="请输入职类名称"></el-input>
			</el-form-item>
			<el-form-item class="formLabel" label="职位" style="margin-right: 40px">
				<el-input v-model="searchOfficeName" placeholder="请输入职位名称"></el-input>
			</el-form-item>
			<el-form-item class="formLabel" label="岗位" style="margin-right: 40px">
				<el-input v-model="searchJobName" placeholder="请输入岗位名称"></el-input>
			</el-form-item>
			<el-button type="primary" @click="getFamilyList">查询</el-button>
		</el-form>
		<el-table :data="gridData" height="400" @current-change="handleCurrentChange" highlight-current-row>
			<el-table-column property="familyName" label="职族"></el-table-column>
			<el-table-column property="className" label="职类"></el-table-column>
			<el-table-column property="subclassName" label="职类子类"></el-table-column>
			<el-table-column property="officeName" label="职位"></el-table-column>
			<el-table-column property="officeCode" label="职级代码"></el-table-column>
			<el-table-column property="jobName" label="岗位"></el-table-column>
			<el-table-column property="level" label="职级"></el-table-column>
		</el-table>
		<div style="text-align: right; margin-top: 10px">
			<el-pagination
				background
				@current-change="handleSysPageChange"
				:current-page="systemPageNo"
				:page-size="systemPageSize"
				layout="total, prev, pager, next"
				:total="systemTotal"
			>
			</el-pagination>
		</div>

		<div slot="footer" class="dialog-footer" style="clear: both; text-align: center">
			<el-button @click="cancelSys">取 消</el-button>
			<el-button type="primary" @click="getSystem">确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
export default {
	props: ['dialogFamily'],
	data() {
		return {
			searchFamilyName: '',
			searchClassName: '',
			searchOfficeName: '',
			searchJobName: '',
			systemPageNo: 1,
			systemPageSize: 20,
			systemTotal: 0,
			gridData: [],
			tableData: [],
			currentRow: ''
		};
	},
	mounted() {
		this.getFamilyList();
	},
	methods: {
		getFamilyList() {
			this.$axios
				.post('/v1/profession/system/list', {
					request: {
						familyName: this.searchFamilyName,
						className: this.searchClassName,
						officeName: this.searchOfficeName,
						jobName: this.searchJobName,
						startTime: '',
						endTime: '',
						pageNo: this.systemPageNo,
						pageSize: this.systemPageSize
					},
					sign: ''
				})
				.then((res) => {
					this.systemTotal = res.totalNum;
					this.gridData = res.list;
					this.tableData = res.list;
				});
		},
		// 筛选表格
		filterTable(key, value) {
			if (value != '') {
				this.gridData = this.tableData.filter((data) => data[key].indexOf(value) > -1);
			} else {
				this.gridData = this.tableData;
			}
		},
		handleSysPageChange(val) {
			this.systemPageNo = val;
			this.getFamilyList();
		},
		// 获取职级体系选中的数据
		handleCurrentChange(val) {
			this.currentRow = val;
		},
		cancelSys() {
			this.currentRow = [];
			this.$emit('update:dialogFamily', false);
		},
		getSystem() {
			this.$emit('setFamily', this.currentRow);
			this.$emit('update:dialogFamily', false);
		}
	}
};
</script>
