import { post } from "./api";
/**
 * 公司列表
 * @param params
 * @returns {*}
 */
export const getCompanyList = (params) => post('/v1/customer/company/list', params);
/**
 * 公司新增
 * @param params
 * @returns {*}
 */
export const saveCompany = (params) => post('/v1/customer/company/add', params);

/**
 * 公司更新
 * @param params
 * @returns {*}
 */
export const updateCompany = (params) => post('/v1/customer/company/update', params);
/**
 * 公司删除
 * @param params
 * @returns {*}
 */
export const delCompany = (params) => post('/v1/customer/company/delete', params);
/**
 * 员工列表
 * @param params
 * @returns {*}
 */
export const getCusStaffList = (params) => post('/v1/customer/staff/list', params);
/**
 * 员工删除
 * @param params
 * @returns {*}
 */
export const delStaff = (params) => post('/v1/customer/staff/delete', params);
/**
 * 员工修改
 * @param params
 * @returns {*}
 */
export const updateStaff = (params) => post('/v1/customer/staff/update', params);
/**
 * 员工新增
 * @param params
 * @returns {*}
 */
export const saveStaff = (params) => post('/v1/customer/staff/add', params);
// 批量删除员工
export const batchDeleteCustomers = (params) => post('/v1/customer/staff/batch/delete', { request: params })
